var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',_vm._l((_vm.links),function(link){return _c('div',{key:link.row_num},[(link.slug === '_separator')?_c('div',{staticClass:"main__menu-separator"}):_vm._e(),(!link.subLinks && link.slug != '_separator')?_c('v-list-item',{staticClass:"v-list-item",class:link.customClass,attrs:{"to":link.route_path,"active-class":`success white--text`}},[(link.icon_name)?_c('v-list-item-icon',[_c('mdicon',{attrs:{"name":link.icon_name}})],1):_vm._e(),_c('v-list-item-title',{staticClass:"sidebar-item--title",domProps:{"textContent":_vm._s(_vm.$t(link.title))}}),(link.slug == 'inbox' && _vm.inboxCount > 0)?_c('v-badge',{staticClass:"app__sidebar-badge",attrs:{"color":"red","content":_vm.inboxCount}}):_vm._e(),(link.slug == 'incoming' && _vm.inboxExtCount > 0)?_c('v-badge',{staticClass:"app__sidebar-badge",attrs:{"color":"red","content":_vm.inboxExtCount}}):_vm._e(),(link.slug == 'permit_list' && _vm.permitCount > 0)?_c('v-badge',{staticClass:"app__sidebar-badge",attrs:{"color":"red","content":_vm.permitCount}}):_vm._e(),(
          link.slug == 'revision_list' && _vm.revisionCount > 0
        )?_c('v-badge',{staticClass:"app__sidebar-badge",attrs:{"color":"red","content":_vm.revisionCount}}):_vm._e(),(
          link.slug == 'draft_approval' && _vm.revisionCount > 0
        )?_c('v-badge',{staticClass:"app__sidebar-badge",attrs:{"color":"red","content":_vm.revisionCount}}):_vm._e(),(
          link.slug == 'disposition' && _vm.dispositionCount > 0
        )?_c('v-badge',{staticClass:"app__sidebar-badge",attrs:{"color":"red","content":_vm.dispositionCount}}):_vm._e()],1):_vm._e(),(link.subLinks && link.slug != 'separator')?_c('v-list-group',{key:link.row_num,attrs:{"no-action":"","prepend-icon":link.icon,"value":_vm.subIsActive(link.parent)},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(link.title)))])]},proxy:true}],null,true)},_vm._l((link.subLinks),function(sublink){return _c('v-list-item',{key:sublink.row_num,class:sublink.customClass,attrs:{"to":sublink.route_path,"active-class":`success white--text`}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(sublink.title)))]),_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(sublink.icon))])],1)],1)}),1):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }