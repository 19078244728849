<template>
  <v-row justify="center">
    <v-dialog v-model="userInfoActive" persistent max-width="400" v-if="currentUser">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("card.user-info") }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <div class="circular_image">
                <img
                  :alt="currentUser ? currentUser.nama : 'User'"
                  :src="currentUser ? currentUser.avatar_path : ''"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <table class="user-info-table">
                <tr>
                  <td width="120px">{{ $t("label.name") }}</td>
                  <td width="10px" class="text-center">:</td>
                  <td>{{ currentUser.nama }}</td>
                </tr>
                <tr>
                  <td>{{ $t("label.nik") }}</td>
                  <td class="text-center">:</td>
                  <td>{{ currentUser.nik }}</td>
                </tr>
                <tr>
                  <td>{{ $t("label.nik-sap") }}</td>
                  <td class="text-center">:</td>
                  <td>{{ currentUser.nik_sap }}</td>
                </tr>
                <tr>
                  <td>{{ $t("label.cellphone") }}</td>
                  <td class="text-center">:</td>
                  <td style="font-weight:bold;">{{ currentUser.nomor_ponsel }}</td>
                </tr>
                <tr>
                  <td>{{ $t("label.job-title") }}</td>
                  <td class="text-center">:</td>
                  <td>{{ currentUser.nama_jabatan }}</td>
                </tr>
                <tr>
                  <td>{{ $t("label.department-name") }}</td>
                  <td class="text-center">:</td>
                  <td>{{ currentUser.nama_bagian }}</td>
                </tr>
                <tr>
                  <td>{{ $t("label.unit-name") }}</td>
                  <td class="text-center">:</td>
                  <td>{{ currentUser.nama_unit }}</td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="closeMe">
            {{ $t("button.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
// import { mdiAlertCircleOutline, mdiClose, mdiTransfer } from "@mdi/js";
export default {
  props: {
    userInfoActive: Boolean,
  },
  data: () => ({}),
  computed: {
    ...mapState(["currentLanguage"]),
    ...mapState("user", ["currentUser"]),
  },
  methods: {
    closeMe() {
      this.$emit("closeUserInfo", false);
    },
    isSecretary(val) {
      let retVal = "False";
      if (!val) {
        this.currentLanguage == "id" ? (retVal = "Bukan") : (retVal = "No");
      } else {
        this.currentLanguage == "id" ? (retVal = "Ya") : (retVal = "Yes");
      }
      return retVal;
    },
    parseElemen(val){
      let result = 'STAFSUBAG/ASISTEN';
      switch (val) {
        case 'KARPIM':
        case 'KARPIM_KANPUS':
          result = 'STAFSUBAG/ASISTEN';
          break;
        case 'KASUBAG':
          result = 'KASUBAG/KABID/KASUBDIV/ASKEP/MASKEP';
          break;
        case 'PJP':
        case 'PJP_KANPUS':
          result = 'KABAG/KADIV/MANAGER';
          break;
        case 'DIREKTUR':
          result = 'DIREKTUR/SEVP';
          break;
        case 'KOMISARIS':
          result = 'DIREKTUR/SEVP/KOMISARIS';
          break;
        default:
          break;
      }
      return result;
    }
  },
};
</script>